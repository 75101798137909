<template>
  <div id="header" class="px-1 sm:px-4 top-0 bg-gray-50 border-b z-50 text-sm sm:text-xl">
    <div class="flex gap-x-2 sm:gap-x-4 items-center justify-evenly p-2">
      <div class="flex items-center gap-x-1">
        <icon-whatsapp class="w-3.5 h-3.5 mt-0.5" />
        <a target="_blank" class="hover:underline text-main" href="https://wa.me/5491162183655?text=">11-6218-3655</a>
      </div>
      <div class="flex items-center gap-x-1">
        <icon-phone class="w-4 h-4 mt-1" />
        <a target="_blank" class="hover:underline text-main" href="tel:0800-888-1005">0800-888-1005</a>
      </div>
      <div class="flex items-center gap-x-1">
        <icon-mail class="w-4 h-4 mt-1.5" />
        <a target="_blank" class="hover:underline text-main"
          href="mailto:ventas@molamaq.com.ar">ventas@molamaq.com.ar</a>
      </div>
    </div>
  </div>


  <!-- Logo Title Cart -->
  <div :class="[
  {
    'justify-evenly pt-4': currentRoute == 'Home',
    'justify-around py-8 bg-top-4': currentRoute != 'Home'
  },
  'flex items-center z-50 bg-no-repeat bg-cover']"
    :style="{ backgroundImage: currentRoute == 'Home' ? '' : 'url(' + require('@/assets/bg-home.jpg') + ')' }">
    <!-- Logo -->
    <logo @click="goToHome" class="cursor-pointer" />

    <!-- Title -->
    <div class="mx-4 sm:mx-4 text-center">
      <span v-if="currentRoute == 'Home'" class="font-bold hidden sm:block text-4xl xl:text-5xl  text-white text-shadow-lg">
        Todos los repuestos en un solo lugar
      </span>
    </div>

    <!-- Cart -->
    <div class="w-20 lg:w-60 flex justify-center items-center">
      <div class="p-5 rounded-full bg-white hover:bg-gray-200 relative cursor-pointer" @click="goToCheckout">
        <icon-cart class="w-10 h-10 text-black"></icon-cart>
        <div class="w-8 h-8 rounded-full bg-main text-white flex items-center justify-center absolute bottom-2 right-2">
          {{ productsInCart }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import { useRoute } from 'vue-router';

import router from "@/router/index";

import IconWhatsapp from "../assets/icons/IconWhatsapp.vue";
import IconPhone from "../assets/icons/IconPhone.vue";
import IconMail from "../assets/icons/IconMail.vue";
import IconCart from "../assets/icons/IconCart.vue";
import Logo from "./Logo.vue";

export default {
  name: "Header",
  components: {
    IconWhatsapp,
    IconPhone,
    IconMail,
    IconCart,
    Logo
  },
  setup() {
    const goToHome = () => {
      router.push({ path: `/` });
    };

    const goToCheckout = () => {
      router.push({ name: `Checkout` });
    };

    const store = useStore();
    const productsInCart = computed(() => {
      return store.state.carrito.length;
    });

    const currentRoute = computed(() => {
      return useRoute().name
    })

    return { goToHome, goToCheckout, productsInCart, currentRoute };
  },
};
</script>
