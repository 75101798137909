<script setup>
import { ref } from "@vue/reactivity";
import router from "@/router/index";
import axios from "axios";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";

import { apiGetMarcas } from "@/utils/endpoints";
import { parseMarcas } from "@/utils/parsers";

let marcas = ref([]);
axios.get(apiGetMarcas()).then((data) => {
    marcas.value = parseMarcas(data.data);
});

const goToMarca = (id) => {
    router.push({ path: `/search/marca/${id}` });
};

const splideOptions = {
    fixedWidth: 150,
    fixedHeight: 80,
    gap: 30,
    type: 'loop',
    drag: false,
    arrows: false,
    pagination: false,
    cover: false,
    focus: "center",
    isNavigation: true,
    updateOnMove: true,
    trimSpace: true,
    speed: 200,
    autoplay: true,
    interval: 2000,
    breakpoints: {
        600: {
            fixedWidth: 100,
            fixedHeight: 80,
        },
    },
};
</script>

<template>
    <Splide :options="splideOptions" v-if="marcas.length > 0" @splide:click.stop="">
        <SplideSlide v-for="(marca, index) in marcas" :key="index" class="relative" @click.stop="goToMarca(marca.furl)">
            <div class="h-40 mx-10 p-2 h-full w-full flex items-center">
                <img :src="marca.image" :alt="marca.text" class="w-full h-full object-contain transform transition duration-500 hover:scale-110"/>
            </div>
        </SplideSlide>
    </Splide>
</template>

<style>
.splide__slide.is-active{
    border: 0 !important
}
</style>