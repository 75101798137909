import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/search/:type/:search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/despiece/:productId",
    name: "Despiece",
    component: () => import("../views/Despiece.vue"),
  },
  {
    path: "/repuesto/:repuestoId",
    name: "Respuesto",
    component: () => import("../views/Repuesto.vue"),
  },
  {
    path: "/datosCompra",
    name: "DatosCompra",
    component: () => import("../views/DatosCompra.vue"),
  },
  {
    path: "/compraRealizada/:id",
    name: "CompraRealizada",
    component: () => import("../views/CompraRealizada.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.title || "Molamaq Repuestos";
  next();
});

export default router;
