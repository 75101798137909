<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" width="497.766px" height="497.766px" viewBox="0 0 497.766 497.766"
        style="enable-background:new 0 0 497.766 497.766;" xml:space="preserve">
        <g>
            <path fill="white" d="M45.902,274.062h254.007v47.744H144.89h-4.936c-7.499-20.58-27.229-35.312-50.364-35.312
		c-22.783,0-42.288,14.31-50.025,34.397c-9.532-2.753-16.515-11.524-16.515-21.948v-2.036
		C23.046,284.285,33.284,274.062,45.902,274.062z M127.696,334.769c0,21.978-17.895,39.864-39.891,39.864
		c-21.977,0-39.87-17.887-39.87-39.864c0-21.997,17.893-39.892,39.87-39.892C109.801,294.877,127.696,312.771,127.696,334.769z
	M115.49,334.769c0-15.272-12.41-27.688-27.686-27.688c-15.271,0-27.676,12.421-27.676,27.688
		c0,15.255,12.411,27.666,27.676,27.666C103.084,362.439,115.49,350.023,115.49,334.769z M439.598,334.769
		c0,21.978-17.893,39.864-39.88,39.864c-21.986,0-39.869-17.887-39.869-39.864c0-21.997,17.883-39.892,39.869-39.892
		C421.705,294.877,439.598,312.771,439.598,334.769z M427.406,334.769c0-15.272-12.421-27.688-27.681-27.688
		c-15.263,0-27.673,12.421-27.673,27.688c0,15.255,12.421,27.666,27.673,27.666C414.985,362.439,427.406,350.023,427.406,334.769z
	M497.766,245.364v65.018c0,4.206-3.424,7.62-7.617,7.62h-39.847c-8.427-18.565-27.131-31.508-48.8-31.508
		c-21.68,0-40.37,12.947-48.808,31.508h-26.881c-4.202,0-7.628-3.414-7.628-7.62V154.439c0-4.213,3.426-7.62,7.628-7.62H426.14
		c4.202,0,10.604,1.4,15.231,7.62L497.766,245.364z M462.209,225.545l-34.286-56.891c-2.65-4.897-5.91-7.608-17.532-7.608h-62.229
		c-4.202,0-7.616,3.401-7.616,7.608v56.891c0,4.219,3.414,7.625,7.616,7.625H454.58C458.796,233.17,462.209,229.764,462.209,225.545
		z M491.924,287.009c0-3.923-3.185-7.113-7.116-7.113c-3.939,0-7.116,3.185-7.116,7.113v13.461c0,3.93,3.177,7.119,7.116,7.119
		c3.932,0,7.116-3.179,7.116-7.119V287.009z M0,260.414h299.909V123.133H0V260.414z" />
        </g>
    </svg>
</template>