<template>
  <div :class="[{ 'min-h-full': currentRoute },'flex flex-col']">
    <Header />
    <router-view class="flex-1" />
  </div>
  <Footer />
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "app",
  components: {
    Header,
    Footer
  },
  setup(){
    const currentRoute = computed(() => {
      return useRoute().name
    })

    return { currentRoute }
  }
};
</script>

<style>
html, body, #app{
  height: 100%;
}
#app {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
}
</style>
