import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    carrito: [],
    user: [],
  },
  mutations: {
    addProducts(state, products) {
      for (let product of products) {
        let index = state.carrito.findIndex((item) => item.id === product.id);
        if (index !== -1) {
          state.carrito[index].cantidad += product.cantidad;
        } else {
          state.carrito.push(product);
        }
      }
    },
    removeProduct(state, idProduct) {
      let index = state.carrito.findIndex((product) => product.id == idProduct);
      state.carrito.splice(index, 1);
    },
    resetCarrito(state) {
      state.carrito = state.carrito.splice();
    },
    setUser(state, user) {
      state.user = state.user.splice();
      state.user.push(...user);
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
