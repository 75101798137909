const BASE_URL = process.env.VUE_APP_API_URL;

// Get ALl
export function apiGetProductos() {
  return BASE_URL + "products";
}
export function apiGetSubsections() {
  return BASE_URL + `product_subsections`;
}
export function apiGetMarcas() {
  return BASE_URL + `product_brands`;
}
export function apiGetRepuestos() {
  return BASE_URL + `replacements`;
}

// Get By id
export function apiGetProducto(id) {
  return BASE_URL + `products/${id}`;
}
export function apiGetDespiece(productId) {
  return BASE_URL + `spare_parts/${productId}`;
}
export function apiGetRepuestosByDespiece(despieceId) {
  return BASE_URL + `spare_parts/${despieceId}/replacements`;
}
export function apiGetRepuesto(repuestoId) {
  return BASE_URL + `replacements/${repuestoId}`;
}

// Search by
export function apiSearchMaquinaos(producto) {
  return BASE_URL + `search/products/${producto}`;
}
export function apiSearchRepuestos(repuesto) {
  return BASE_URL + `search/replacements/${repuesto}`;
}
export function apiSearchSubsections(subsection) {
  return BASE_URL + `products/subsection/${subsection}`;
}
export function apiSearchMarcas(marca) {
  return BASE_URL + `products/brand/${marca}`;
}

// Compra
export function apiComprar() {
  return BASE_URL + `checkout`;
}
export function apiGetProvincias() {
  return BASE_URL + `select/provinces`;
}
export function apiGetFacturas() {
  return BASE_URL + `select/invoices_types`;
}
export function apiGetTiposPago() {
  return BASE_URL + `orders/payment_types`;
}
export function apiGetLogin() {
  return BASE_URL + `login`;
}

export function getCompra(idOrder) {
  return BASE_URL + `order/${idOrder}`;
}
export function apiGetCostoEnvio() {
  return BASE_URL + `orders/shipping_cost`;
}

export function apiSearchMaquina(search_term) {
  return BASE_URL + `search/products/${search_term}`;
}
export function apiSearchReplacement(search_term) {
  return BASE_URL + `search/replacements/${search_term}`;
}