const IMAGE_PRODUCTS_URL = process.env.VUE_APP_IMAGE_PRODUCTS_URL;
const IMAGE_SUBSECTION_URL = process.env.VUE_APP_IMAGE_SUBSECTION_URL;
const IMAGE_BRANDS_URL = process.env.VUE_APP_IMAGE_BRANDS_URL;
const BACK_URL = process.env.VUE_APP_BACK_URL;

export const parseMaquina = (data) => {
  return data.map((item) => {
    return {
      image: IMAGE_PRODUCTS_URL + item.image,
      text: `${item.title} (${item.code})`,
      id: item.code,
      value: `${item.title} ${item.code}`,
    };
  });
};

export const parseRepuestos = (data) => {
  return data
    .map((item) => {
      return {
        image: BACK_URL + `${item.image_path}`,
        text: `${item.description} (${item.replacement_code})`,
        id: item.replacement_id,
        value: `${item.description} ${
          item.replacement_code
        } ${item.replacement_for.join(" ")}`,
        replace: item.replacement_for,
        original: item.original,
      };
    })
    .filter(item => item.original);
};

export const parseProduct = (data) => {
  return data.map((item) => {
    return {
      text: item.title,
      image: IMAGE_PRODUCTS_URL + item.image,
      id: item.code,
      value: `${item.title} ${item.code}`,
    };
  });
};

export const parseDespiece = (data) => {
  return data.map((item) => {
    return {
      image: BACK_URL + item.image_path,
      text: item.name,
      id: item.spare_part_id,
      parts: item.parts.map((part) => {
        return {
          ...part,
          ref: part.reference_number,
          width: parseInt(part.width),
          height: parseInt(part.height),
          x: parseInt(part.x),
          y: parseInt(part.y),
        };
      }),
    };
  });
};

// Pantalla Search
export const parseSearchProducts = (data) => {
  return data
    .map((item) => {
      return {
        image: IMAGE_PRODUCTS_URL + item.image,
        text: `${item.title} (${item.code})`,
        id: item.code,
        value: `${item.title} ${item.code}`,
      };
    })
    .sort((a, b) => (a.text > b.text ? 1 : -1));
};
export const parseSearchRepuestos = (data) => {
  return data
    .map((item) => {
      return {
        image: BACK_URL + item.image_path,
        text: `${item.description} (${item.replacement_code})`,
        id: item.replacement_id,
        value: `${item.description} ${item.replacement_code}`,
      };
    })
    .sort((a, b) => (a.text > b.text ? 1 : -1));
};

export const parseSubsections = (data) => {
  return data
    .map((item) => {
      return {
        image: IMAGE_SUBSECTION_URL + item.image,
        text: `${item.title}`,
        id: item.subsection_id,
        value: item.subsection_id,
        furl: item.furl
      };
    })
    .sort((a, b) => (a.text > b.text ? 1 : -1));
};

export const parseMarcas = (data) => {
  return data
    .map((item) => {
      return {
        image: IMAGE_BRANDS_URL + item.logo_filename,
        text: `${item.title}`,
        id: item.brand_id,
        value: item.brand_id,
        furl: item.furl
      };
    })
    .sort((a, b) => (a.text > b.text ? 1 : -1));
};

export const parseRepuestosTable = (data) => {
  // TODO Ref Image
  return data
    .map((item) => {
      return {
        cantidad: 0,
        id: item.replacement_id,
        code: item.replacement_code,
        ref: item.reference_number,
        nombre: item.description,
        replace: item.replacement_for,
        original: item.original,
        compatibilidad: item.compatibility,
        precio: parseFloat(item.price),
        stock: item.stock,
        image: BACK_URL + item.image_path,
      };
    })
    .sort((a, b) => (a.original > b.original ? 1 : -1))
    .sort((a, b) => (a.ref > b.ref ? 1 : -1));
};

export const parseRepuesto = (item) => {
  return {
    id: item.replacement_id,
    nombre: item.description,
    precio: parseFloat(item.price),
    codigo: item.replacement_code,
    compatibilidad: item.compatibility.map((c) => {
      return {
        code: c.code,
        text: c.title,
      };
    }),
    replace: item.replacement_for,
    stock: parseFloat(item.price) ? item.stock : 0,
    cantidad: 0,
    image: BACK_URL + item.image_path,
  };
};
