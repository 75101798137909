<script setup>
let text = ''
</script>

<template>
    <transition name="fade">
        <div class="fixed bottom-10 right-10">
            <a :href="`https://wa.me/5491162183655?text=${text}`" target="_blank" id="whatsapp-msg" style=""
                class="flex items-center gap-x-1">
                <!-- <div class="bg-white p-2 rounded">Te podemos ayudar!</div> -->
                <img src="@/assets/whatsapp-icon.png" width="64" height="64" alt="Whatsapp" class="pngshadow"
                    style="" />
            </a>
        </div>
    </transition>
</template>