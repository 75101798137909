<template>
  <div class="mt-8 container w-full mx-auto justify-center">
    <div class="
        w-full
        sm:w-4/5
        md:w-2/3
        sm:m-4 sm:mx-auto
        bg-white
        sm:border-8
        border-black
        p-4
        sm:rounded-3xl
      ">
      <h1 class="text-3xl font-bold select-none">Buscá tu repuesto por:</h1>

      <!-- Tabs -->
      <div class="my-3 flex flex-wrap gap-1">
        <div v-for="tab in tabs" :key="tab.id" :class="[
          {
            'bg-main text-white ': tabActive === tab.id,
          },
          { 'hover:bg-gray-100 bg-gray-400': tabActive !== tab.id },
          'flex-shrink-0  text-white font-sans text-base  py-2 px-4 rounded-lg shadow-md hover:bg-main-darker focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-purple-200 cursor-pointer select-none',
        ]" @click="tabActive = tab.id">
          {{ tab.text }}
        </div>
      </div>

      <!-- Input búsqueda -->
      <!-- Máquina y Repuesto -->
      <div class="relative" v-if="canSearch">
        <div class="border-2 py-1 px-3 flex justify-between rounded">
          <input class="flex-grow outline-none text-gray-600 focus:text-black" type="text" v-model="search"
            :placeholder="getPlaceholder" @keyup.enter="goToSearch()" v-on:keyup="searchProduct" />
          <button>
            <IconSearch class="text-gray-600 h-6 w-6" @click="search.length > 0 ? goToSearch() : ''" />
          </button>
        </div>

        <!-- Resultados -->
        <transition name="fadeHeight">
          <div v-if="activeSearch" class="
              w-full
              rounded-b
              border-2
              absolute
              top-8
              bg-white
              shadow-md
              overflow-y-scroll
            " style="max-height: 15rem">
            <div v-for="product in productsFilter" :key="product.id" class="
                p-3
                flex
                items-center
                gap-x-2
                cursor-pointer
                hover:bg-gray-200
              " @click="clickSelect(product.id)">
              <div class="w-8 h-8">
                <img :src="product.image" alt="" class="w-full h-full object-contain" />
              </div>
              <div class="flex flex-col">
                <div class="flex items-center relative">
                  {{ product.text }}
                </div>

                <!-- (String) Reemplaza a -->
                <div v-if="product.replace?.length > 0" class="text-sm truncate">
                  Reemplaza a {{ product.replace.join(", ") }}
                </div>
              </div>
            </div>
            <div v-if="productsFilter.length === 0 && !loading" class="p-3">
              <span>No se encontraron resultados para {{ search }}</span>
            </div>
            <div v-if="loading" class="p-3">
              <span>Cargando...</span>
            </div>
          </div>
        </transition>
      </div>

      <!-- Listado Tipo -->
      <div v-if="tabActive == 'subseccion'" class="mt-5">
        <div class="mb-2 flex items-center gap-x-1 relative text-gray-600">
          <input type="text" placeholder="Buscar por tipo" class="
              border-2
              py-1
              px-3
              flex
              justify-between
              rounded
              flex-grow
              outline-none
              focus:text-black
            " v-model="searchSubseccion" />
          <x class="w-5 h-5 absolute right-2 top-2 cursor-pointer" @click="searchSubseccion = ''" />
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <div v-for="tipo in subseccionesComputed" :key="tipo.id"
            class="mt-2 flex items-center gap-x-3 cursor-pointer group" @click="goToCategory(tipo.furl)">
            <div class="w-8 h-8 flex justify-center items-center">
              <img :src="tipo.image" class="
                  filter
                  contrast-0
                  group-hover:contrast-100 group-hover:invert
                  object-contain
                " alt="" />
            </div>
            <span class="group-hover:font-bold">{{ tipo.text }}</span>
          </div>
        </div>
      </div>

      <!-- Listado marcas -->
      <div v-if="tabActive == 'marca'" class="mt-5">
        <div class="grid grid-cols-2 sm:grid-cols-3">
          <div v-for="marca in marcas" :key="marca.id" class="mt-2 flex items-center gap-x-3 cursor-pointer group"
            @click="goToCategory(marca.id)">
            <div class="w-full h-20 p-4 flex justify-center items-center">
              <img :src="marca.image" class="
                  h-full
                  w-full
                  object-contain
                  transform
                  hover:scale-105
                  transition
                  duration-200
                " :alt="marca.text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "axios";
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import {
  apiGetSubsections,
  apiSearchMaquina,
  apiSearchReplacement
} from "@/utils/endpoints";
import {
  parseMaquina,
  parseSubsections,
  parseRepuestos,
} from "@/utils/parsers";

import IconSearch from "../assets/icons/IconSearch.vue";
import X from "../assets/icons/X.vue";

export default {
  name: "Search",
  components: {
    IconSearch,
    X,
  },
  setup() {
    let search = ref("");
    let activeSearch = ref(false);
    let loading = ref(false);

    let tabActive = ref("maquina");
    const tabs = [
      {
        id: "maquina",
        text: "Máquina",
        placeholder: "Ingrese el modelo o código de la máquina",
        canSearch: true,
      },
      {
        id: "repuesto",
        text: "Repuesto",
        placeholder: "Ingrese el nombre o código del repuesto",
        canSearch: true,
      },
      {
        id: "subseccion",
        text: "Tipo de máquina",
        placeholder: "Ingrese el tipo de máquina",
      },
    ];
    const getPlaceholder = computed(() => {
      return tabs.find((tab) => tab.id === tabActive.value).placeholder;
    });

    const canSearch = computed(() => {
      return tabs.find((tab) => tab.id === tabActive.value).canSearch;
    });

    // Si la búsqueda tiene más de 3 caracteres, activa el los resultados
    watch(
      () => search.value,
      (newSearch) => {
        activeSearch.value = newSearch.length >= 3;
      }
    );

    // Borra el input al cambiar de tab
    watch(
      () => tabActive.value,
      () => {
        search.value = "";
        productos.value = productos.value.splice();
      }
    );

    // GETS
    let productos = ref([]);
    let tipos = ref([]);
    axios.get(apiGetSubsections()).then((data) => {
      tipos.value = parseSubsections(data.data);
    });

    // GET search maquinas & repuestos
    function searchProduct() {
      abortController();
      loading.value = true;
      productos.value = productos.value.splice();
      let isMaquina = tabActive.value === "maquina";
      let url = isMaquina ? apiSearchMaquina : apiSearchReplacement;
      let parser = isMaquina ? parseMaquina : parseRepuestos;
      axios.get(url(search.value), { signal }).then(response => {
        productos.value = [...parser(response.data)];
        loading.value = false;
      })
    }

    // Handler Cancel request
    let controller;
    let signal;
    const initController = () => {
      controller = new AbortController();
      signal = controller.signal;
    }
    initController();
    function abortController() {
      // Cancel previous requests
      controller.abort();
      initController();
    }

    // Productos y Repuestos
    const productsFilter = computed(() => {
      return productos.value;
    });

    // Buscador subsecciones
    let searchSubseccion = ref("");
    const subseccionesComputed = computed(() => {
      return tipos.value.filter((subseccion) => {
        let regex = new RegExp(`${searchSubseccion.value.toLowerCase()}`);
        let value = subseccion.text.toLowerCase();
        return regex.test(value);
      });
    });

    let isReplacement = (replace) => {
      return replace.filter((repuesto) => {
        let regex = new RegExp(`${search.value.toLowerCase()}`);
        let value = repuesto.toString().toLowerCase();
        return regex.test(value);
      });
    };

    // Acciones de Router
    const clickSelect = (id) => {
      if (tabActive.value == "maquina") {
        goToDespiece(id);
      } else {
        goToRepuesto(id);
      }
    };
    const goToDespiece = (id) => {
      router.push({ path: `/despiece/${id}` });
    };
    const goToRepuesto = (id) => {
      router.push({ path: `/repuesto/${id}` });
    };
    // Subseccion y marca
    const goToCategory = (id) => {
      router.push({ path: `/search/${tabActive.value}/${id}` });
    };
    // Pantalla de search
    const goToSearch = () => {
      router.push({ path: `/search/${tabActive.value}/${search.value}` });
    };

    return {
      tabs,
      tabActive,
      getPlaceholder,
      tipos,
      canSearch,
      search,
      productsFilter,
      activeSearch,
      goToSearch,
      goToDespiece,
      clickSelect,
      goToCategory,
      searchSubseccion,
      subseccionesComputed,
      isReplacement,
      searchProduct,
      loading
    };
  },
};
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  max-height: 200px;
  overflow: hidden;
}

.fadeHeight-enter-from,
.fadeHeight-leave-to {
  opacity: 0;
  overflow: hidden;
  max-height: 0px;
}
</style>
