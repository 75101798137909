<template>
  <div id="homeMainContainer" class="-mt-28 pt-28 relative bg-black bg-no-repeat bg-cover flex flex-col"
    :style="{ backgroundImage: 'url(' + require('@/assets/bg-home.jpg') + ')' }">

    <!-- Buscador -->
    <div>
      <search />
    </div>

    <!-- Envio Tarjeta -->
    <div class="mt-4 mb-10 w-4/5 sm:w-2/3 md:w-full mx-auto flex flex-col md:flex-row justify-center gap-4">
      <div class="p-4 rounded-xl bg-black text-white flex items-center gap-3">
        <icon-camion class="w-12 h-12"></icon-camion>
        Envios gratis en compras desde $4000
      </div>
      <div class="p-4 rounded-xl bg-black text-white flex items-center gap-3">
        <icon-tarjeta class="w-12 h-12"></icon-tarjeta>
        Tarjeta de crédito o transferencia
      </div>
    </div>

    <!-- Marcas -->
    <div class="w-full flex flex-col flex-1 justify-end">
      <div class="bg-white border">
        <home-marcas-carousel />
      </div>
    </div>

    <!-- Whatsapp -->
    <whatsapp class="fixed right-4 bottom-44" />

  </div>
</template>

<script>
import Search from "@/components/HomeSearch.vue";
import IconCamion from "../assets/icons/IconCamion.vue";
import IconTarjeta from "../assets/icons/IconTarjeta.vue";
import HomeMarcasCarousel from "../components/HomeMarcasCarousel.vue";
import Whatsapp from "../components/elements/Whatsapp.vue";

export default {
  name: "Home",
  components: {
    Search,
    IconCamion,
    IconTarjeta,
    HomeMarcasCarousel,
    Whatsapp
},
  setup() {

  },
};
</script>

<style>
#homeMainContainer {
  /* min-height: 89vh; */
}
</style>